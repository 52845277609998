import React from 'react';
import { useUser } from 'Context/user';
import { TopbarItem, Button } from '@make.tv/lib-web-uicomponents';

/**
 * Use this component to check for user role and to render role-specific content
 * eg. user impersonation modal is only available to admin
 */
export const RoleGuard = ({ role, children }) => {
  const { hasRole } = useUser();

  if (!Array.isArray(role)) {
    role = [role];
  }

  return (
    <>{role.some((r) => hasRole(r)) ? children : null}</>
  );
};

export const RealRoleGuard = ({ role, children }) => {
  const { hasRealRole } = useUser();

  if (!Array.isArray(role)) {
    role = [role];
  }

  return (
    <>{role.some((r) => hasRealRole(r)) ? children : null}</>
  );
};

export const TopNavRoleGuard = ({ role, children, title }) => {
  const { hasRole } = useUser();

  if (!Array.isArray(role)) {
    if (role === 'MONITOR' || role === 'SCHEDULE' || role === 'WATCH' || role === 'PLAYOUT'
      || role === 'DIRECTORY' || role === 'REPORT' || role === 'PERMISSION_CLIENT_API_DOCS'
    ) {
      role = [role];
      return (
        <>
          {role.some((r) => hasRole(r))
            ? children
            : (
              <TopbarItem>
                <Button color="tertiary" disabled title="Paid feature, contact LTN">{title}</Button>
              </TopbarItem>
            )}
        </>
      );
    }
    role = [role];
  }

  return (
    <>{role.some((r) => hasRole(r)) ? children : null}</>
  );
};
