export const ROLE_NOC = 'NOC';
export const ROLE_LTN_ADMIN = 'LTN_ADMIN';
export const ROLE_ACCOUNT_ADMIN = 'ACCOUNT_ADMIN';
export const PERMISSION_ACCOUNTS = 'ACCOUNTS';
export const PERMISSION_CUE = 'CUE';
export const PERMISSION_DIRECTORY = 'DIRECTORY';
export const PERMISSION_REPORT = 'REPORT';
export const PERMISSION_MONITOR = 'MONITOR';
export const PERMISSION_NOC = 'NOC';
export const PERMISSION_BOOKINGS = 'SCHEDULE';
export const PERMISSION_WATCH = 'WATCH';
export const PERMISSION_MAP = 'MAP';
export const PERMISSION_CLIENT_API_DOCS = 'CLIENT_API_DOCS';
export const PERMISSION_PLAYOUT = 'PLAYOUT';
