import React from 'react';
import { Link } from 'react-router-dom';
import { Button, TopbarItem } from '@make.tv/lib-web-uicomponents';
import { RoleGuard } from 'Components/auth/role-guard';
import { SecondaryNavbar as SecondaryNavbarStyled } from './styles';

const PATH_VIEW_GRID = '/grid';

function buildRoute(activeRoute, route, pathnameCurrent) {
  const routeNew = `/${activeRoute.path}${route.path}`;

  if (route.hasViewGrid && pathnameCurrent.endsWith(PATH_VIEW_GRID)) {
    return `${routeNew}${PATH_VIEW_GRID}`;
  }

  return routeNew;
}

export const SecondaryNavbar = ({ activeRoute, pathname, titleOverwrite }) => {
  if (!activeRoute || !activeRoute.children || activeRoute.children.length === 0) {
    return null;
  }

  const filteredRoutes = activeRoute.children.filter((route) => route.ui !== 'hidden');

  return (
    <SecondaryNavbarStyled menuLeft={filteredRoutes.map((route) => (
      <RoleGuard {...route.auth} key={route.path}>
        <TopbarItem>
          <Link
            to={buildRoute(activeRoute, route, pathname)}
            exact={(route.exact || !route.path) || undefined}
          >
            <Button color={route.color || 'tertiary'} id={route.id} active={pathname.includes(`/${activeRoute.path}${route.path}`)}>
              {route.icon}
              <span>{titleOverwrite && titleOverwrite[route.path] ? `${route.title} (${titleOverwrite[route.path]})` : route.title}</span>
            </Button>
          </Link>
        </TopbarItem>
      </RoleGuard>
    ))}
    />
  );
};
