import { lazy } from 'react';
import { PERMISSION_ACCOUNTS } from 'Constants';

export default {
  ui: 'menuLeft',
  auth: { role: PERMISSION_ACCOUNTS },
  path: 'accounts',
  title: 'Accounts',
  urlParams: '/:page?',
  component: lazy(() => import('./index')),
  children: [
    {
      path: '/users',
      urlParams: '/:id?/:action?',
      component: lazy(() => import('./views/users-view')),
      title: 'Users',
      pageTitle: 'Users',
    },
    {
      path: '/accounts',
      urlParams: '/:id?/:action?',
      component: lazy(() => import('./views/accounts-view')),
      title: 'Accounts',
      pageTitle: 'Accounts',
    },
    {
      path: '/account_acl',
      urlParams: '/:id?/:action?',
      component: lazy(() => import('./views/account-acl-view')),
      title: 'Account ACL',
      pageTitle: 'Account ACL',
    },
    {
      path: '/account_permissions',
      component: lazy(() => import('./views/account-permissions-view')),
      title: 'Account Permissions',
      pageTitle: 'Account Permissions',
    },
    {
      path: '/customers',
      urlParams: '/:id?/:action?',
      component: lazy(() => import('./views/customers-view')),
      title: 'Contract Customers',
      pageTitle: 'Customers',
    },
    {
      path: '/booking-contracts',
      urlParams: '/:id?/:action?',
      component: lazy(() => import('./views/booking-contracts-view')),
      title: 'Booking Contracts',
      pageTitle: 'Booking Contracts',
    },
    {
      path: '/long_lived_tokens',
      urlParams: '/:id?/:action?',
      component: lazy(() => import('./views/long-lived-tokens-view')),
      title: 'Long Lived Tokens',
      pageTitle: 'Long Lived Tokens',
    },
  ],
};
