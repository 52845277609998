import React, { useMemo, useState, useCallback } from 'react';
import { useQuery } from 'react-query';
import { ThemeProvider } from 'styled-components';
import { Modal, Form, Radio } from 'semantic-ui-react';
import { Button } from '@make.tv/lib-web-uicomponents';

import { useAuth } from 'Context/auth';
import { useUser } from 'Context/user';
import { useTheme } from 'Context/theme';
import { useRouter } from 'Context/hooks';
import { useNotifications } from 'Context/notifications';
import { Notifications } from 'Components/notifications';
import { readServerTime } from 'Components/current-time';
import { GlobalStyle } from 'Styles/global';
import theme from 'Styles/theme';
import { cn } from 'Utils/cn';
import { Resource } from 'Services/resources';

import { PageHeader } from './page-header';
import { PageFooter } from './page-footer';
import { PageTitle } from './page-title';

/**
 * Page layout wrapper
 * Loads the css theme, global styles, and all general components (eg. header, footer)
 */
export const AppLayout = ({ children }) => {
  const { mode, vars } = useTheme();
  const { activeRoute: [route = {}] } = useRouter();
  const { showSuccess, showError } = useNotifications();
  const { isAuthenticated } = useAuth();
  const {
    user, acceptedLocalCookies, setAcceptedLocalCookies, fetchUser
  } = useUser();
  const [cookieSettingModal, setCookieSettingModal] = useState();
  const [cookieSetting, setCookieSetting] = useState();
  const [cookieSaveIsLoading, setCookieSaveIsLoading] = useState();

  const { title: routeTitle } = route;
  const pageClassName = useMemo(() => cn(routeTitle), [routeTitle]);

  const { data: latestPolicySet } = useQuery(
    ['stateVariable/latestPolicySet'],
    () => Resource.get('state_variables', 'latest_policy_set', {
      'field_mask.value': '',
      field_mask_pstv: true,
    }),
    { enabled: isAuthenticated, retry: false },
  );

  const showPolicies = useMemo(() => isAuthenticated && user && latestPolicySet?.value && (
    user.latest_policy_set_accepted !== latestPolicySet.value
    || (user.cookies_acception !== 'ALWAYS_ACCEPT' && user.cookies_acception !== 'ESSENTIAL_ONLY' && !acceptedLocalCookies)
  ), [isAuthenticated, user, acceptedLocalCookies, latestPolicySet]);

  const handlePolicyAcceptances = useCallback(async () => {
    if (user.latest_policy_set_accepted !== latestPolicySet.value) {
      setCookieSettingModal(true);

      await Resource.patch('users', user.user_id, {
        user: {
          privacy_and_msa_acceptance_datetime: new Date(readServerTime()).toISOString(),
          latest_policy_set_accepted: latestPolicySet.value,
          cookies_acception_null: 'NULL_VALUE',
        },
        field_mask_pstv: true,
      })
        .then(fetchUser)
        .catch(() => showError('Error saving cookie policy, privacy policy, and MSA agreements.'));
    } else if (!user.cookies_acception) {
      setCookieSettingModal(true);
    }
    setAcceptedLocalCookies(true);
  }, [showError, user, fetchUser, setAcceptedLocalCookies, setCookieSettingModal, latestPolicySet]);

  const handleCookieSettingConfirm = useCallback(async () => {
    if (!cookieSetting) {
      showError('Please select a cookie setting.');
      return;
    }
    setCookieSaveIsLoading(true);

    await (Resource.patch('users', user.user_id, {
      user: { cookies_acception: cookieSetting },
      field_mask_pstv: true,
    }))
      .then(fetchUser)
      .then(() => {
        setCookieSettingModal(null);
        showSuccess('Cookie setting saved.');
      })
      .catch((error) => showError(`${error.message}. Please try again.`))
      .finally(() => setCookieSaveIsLoading(false));
  }, [
    showSuccess,
    showError,
    user,
    cookieSetting,
    fetchUser,
    setCookieSettingModal,
    setCookieSaveIsLoading,
  ]);

  return (
    <ThemeProvider theme={{ ...theme, vars, mode }}>
      <PageTitle />
      <GlobalStyle />
      <PageHeader />
      <Notifications />

      <div className={`container relative py-4 page-${pageClassName}`} style={{ paddingBottom: 0 }}>
        {children}
      </div>
      <PageFooter />

      {/* eslint-disable-next-line */}
      {showPolicies && (
        <Modal open size="tiny">
          <Modal.Header>
            We Value Your Privacy
          </Modal.Header>
          <Modal.Content>
            {`Our website requires an authentication cookie for your login to function properly.
              By clicking "Accept", you agree LTN Global
              Communications can store this cookie on your device in accordance with our `}
            <a
              href="https://ltnglobal.com/cookie-policy"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: 'lightblue', textDecoration: 'underline' }}
            >
              Cookie Policy
            </a>
            {', as well as with our '}
            <a
              href="https://ltnglobal.com/privacy-policy"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: 'lightblue', textDecoration: 'underline' }}
            >
              Privacy Policy
            </a>
            {' and '}
            <a
              href="https://ltnglobal.com/master-service-agreement"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: 'lightblue', textDecoration: 'underline' }}
            >
              Terms of Service
            </a>
            .
          </Modal.Content>
          <Modal.Actions>
            <Button
              onClick={handlePolicyAcceptances}
              type="submit"
            >
              Accept
            </Button>
          </Modal.Actions>
        </Modal>
      )}

      {cookieSettingModal && (
        <Modal open size="tiny" onClose={() => setCookieSettingModal(null)}>
          <Modal.Header>
            Save Your Privacy Settings
          </Modal.Header>
          <Modal.Content>
            {'Choose your '}
            <a
              href="https://ltnglobal.com/cookie-policy"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'underline' }}
            >
              cookie policy
            </a>
            {' acceptance setting:'}
            <Form style={{ margin: '20px' }}>
              <Form.Field>
                <Radio
                  label="Always Accept All Cookies - Do Not Ask Again"
                  name="radioGroup"
                  value="ALWAYS_ACCEPT"
                  checked={cookieSetting === 'ALWAYS_ACCEPT'}
                  onChange={() => setCookieSetting('ALWAYS_ACCEPT')}
                />
              </Form.Field>
              <Form.Field>
                <Radio
                  label="Always Accept Only Essential Cookies - Do Not Ask Again"
                  name="radioGroup"
                  value="ESSENTIAL_ONLY"
                  checked={cookieSetting === 'ESSENTIAL_ONLY'}
                  onChange={() => setCookieSetting('ESSENTIAL_ONLY')}
                />
              </Form.Field>
              <Form.Field>
                <Radio
                  label="Ask every time my browser's local storage clears"
                  name="radioGroup"
                  value="LOCAL_STORAGE"
                  checked={cookieSetting === 'LOCAL_STORAGE'}
                  onChange={() => setCookieSetting('LOCAL_STORAGE')}
                />
              </Form.Field>
            </Form>
            {'Note: This setting can be updated at any time in your '}
            <a
              href="/profile/profile-view"
              style={{ textDecoration: 'underline' }}
            >
              user profile
            </a>
            .
          </Modal.Content>
          <Modal.Actions>
            <Button
              onClick={handleCookieSettingConfirm}
              type="submit"
              loading={cookieSaveIsLoading}
            >
              Save
            </Button>
          </Modal.Actions>
        </Modal>
      )}
    </ThemeProvider>
  );
};
