export const env = {
  ...process.env,
};

let domain = `${env.REACT_APP_ENV}.ltnportal.com`;

if (env.REACT_APP_ENV === 'prod') {
  domain = 'www.ltnportal.com';
} else if (env.REACT_APP_ENV === 'local' || env.REACT_APP_ENV === 'dev') {
  domain = '';
}

export const DOMAIN = domain;
