import { lazy } from 'react';
import { PERMISSION_NOC, PERMISSION_CLIENT_API_DOCS } from 'Constants';

export default {
  ui: 'menuRight',
  path: 'help',
  title: 'Help',
  urlParams: '/:page?',
  component: lazy(() => import('./index')),
  children: [
    {
      path: '/info',
      component: lazy(() => import('./components/help-view')),
      title: 'Help Info',
      pageTitle: 'Help Info',
    },
    {
      auth: { role: PERMISSION_NOC },
      path: '/transport-docs',
      component: lazy(() => import('./components/api-docs')),
      title: 'Transport API Documentation',
      pageTitle: 'Transport API Documentation',
    },
    {
      auth: { role: PERMISSION_CLIENT_API_DOCS },
      path: '/client-docs',
      component: lazy(() => import('./components/api-docs')),
      title: 'Client API Documentation',
      pageTitle: 'Client API Documentation',
    },
  ],
};
