import { useMemo } from 'react';
import {
  useParams,
  useLocation,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';
import queryString from 'query-string';

import { useUser } from 'Context/user';
import { authenticatedRoutes, anonymousRoutes } from 'Routes/routes-config';

const matchRoute = (parts, routes) => {
  if (!routes) {
    return [];
  }

  const path = parts[0] || '';
  const route = routes.find((d) => d.path.replace(/^\//, '') === path);

  if (!route) {
    return [];
  }

  return [route, ...matchRoute(parts.slice(1), route.children)];
};

export function useRouter() {
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();
  const userData = useUser();

  const routes = useMemo(
    () => (userData && userData.user ? authenticatedRoutes : anonymousRoutes),
    [userData]
  );

  const activeRoute = useMemo(
    () => matchRoute(location.pathname.split('/').slice(1), routes),
    [location.pathname, routes]
  );

  // Return our custom router object
  // Memoize so that a new object is only returned if something changes
  return useMemo(
    () => ({
      // For convenience add push(), replace(), pathname at top level
      push: history.push,
      replace: history.replace,
      pathname: location.pathname,
      // Merge params and parsed query string into single "query" object
      // so that they can be used interchangeably.
      // Example: /:topic?sort=popular -> { topic: "react", sort: "popular" }
      params: {
        ...queryString.parse(location.search), // Convert string to object
        ...params,
      },
      // Include match, location, history objects so we have
      // access to extra React Router functionality if needed.
      match,
      location,
      history,
      routes,
      activeRoute,
    }),
    [history, location, params, match, routes, activeRoute]
  );
}
