import { lazy } from 'react';
import { PERMISSION_NOC, PERMISSION_PLAYOUT } from 'Constants';

export default {
  ui: 'menuLeft',
  // TODO: change if necessary when Schedule access token is known
  auth: { role: PERMISSION_PLAYOUT },
  path: 'playout',
  title: 'Playout',
  component: lazy(() => import('./index')),
  children: [
    {
      path: '/list',
      title: 'Server',
      pageTitle: 'Server',
    },
    {
      path: '/multichannel',
      title: 'Control',
      pageTitle: 'Control',
    },
    {
      path: '/multichannel/:id',
      title: 'Control detail view',
      pageTitle: 'Control channels',
      ui: 'hidden',
    },
    {
      path: '/dashboard',
      title: 'Dashboard',
      pageTitle: 'Dashboard',
    },
    {
      path: '/dashboard/:id',
      title: 'Dashboard',
      pageTitle: 'Dashboard',
      ui: 'hidden',
    },
    {
      path: '/schedule-instances',
      auth: { role: PERMISSION_NOC },
      title: 'Schedule Instances',
      pageTitle: 'Schedule Instances'
    },
    {
      auth: { role: PERMISSION_NOC },
      path: '/schedule_access_token',
      urlParams: '/:id?/:action?',
      title: 'Access Tokens',
      pageTitle: 'Access Tokens',
    },
  ],
};
